import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MasterLayoutComponent } from './pages/layout/master-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { WorksComponent } from './pages/works/works.component';
import { WorkDetailComponent } from './pages/work-detail/work-detail.component';
import { WorkService } from './components/works.service';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';

@NgModule({
  declarations: [
    AppComponent,
    MasterLayoutComponent,
    HomeComponent,
    AboutComponent,
    WorksComponent,
    WorkDetailComponent,
    MainHeaderComponent,
    MainFooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    WorkService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
