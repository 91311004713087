<section class="home">
  <div class="container">
    <div class="inner">
      <figure>
        <div>
          <img src="/assets/images/content/logo.png" alt="">
        </div>
        <figcaption>
          <h1>Egemen Ünüvar</h1>
          <h2>Front-End Developer</h2>
          <ul>
            <li><a href="https://github.com/egemenu" target="_blank"><i class="icon-github"></i></a></li>
            <li><a href="https://www.linkedin.com/in/egemenu" target="_blank"><i class="icon-linkedin"></i></a></li>
            <li><a href="https://codepen.io/egemenu" target="_blank"><i class="icon-codepen"></i></a></li>
            <li><a href="https://open.spotify.com/user/egemeu" target="_blank"><i class="icon-spotify"></i></a></li>
            <li><a href="http://t.me/egemen" target="_blank"><i class="icon-telegram"></i></a></li>
            </ul>
        </figcaption>
      </figure>
    </div>
  </div>
  
  
</section>