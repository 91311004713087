import { Component } from '@angular/core';
import { WorkService } from 'src/app/components/works.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-work-detail',
  templateUrl: './work-detail.component.html',
  styleUrls: ['./work-detail.component.scss']
})
export class WorkDetailComponent {

  work: any;

  constructor(
    private workService: WorkService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    let selectedWork: any;

    this.route.params.subscribe(
      (params: Params) => {
        selectedWork = this.workService.getWork(params['url']);
        console.log(selectedWork);
        if (selectedWork) {
          this.work = selectedWork;
        } else {
          this.router.navigate(['/404']);
        }
      }
    );
  }
}
