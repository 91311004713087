import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { WorksComponent } from './pages/works/works.component';
import { WorkDetailComponent } from './pages/work-detail/work-detail.component';
import { MasterLayoutComponent } from './pages/layout/master-layout.component';

const routes: Routes = [
  { path: '', component: MasterLayoutComponent, children: [
    { path: 'about', component: AboutComponent, data: { page: 'about'} },
    { path: 'works', component: WorksComponent, data: { page: 'works'} },
    { path: 'works/:url', component: WorkDetailComponent, data: { page: 'work-detail' }},
    { path: '', component: HomeComponent }
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
