import { Component, OnInit } from '@angular/core';
import { WorkService } from 'src/app/components/works.service';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss']
})
export class WorksComponent implements OnInit {

  works = [];

  constructor(private workService: WorkService) { 

  }
  
  ngOnInit() {
    this.works = this.workService.getWorks()
  }
 }
