<section class="works">
  <div class="container">
    <div class="inner">
      <h1>Works</h1>
      <ul>
        <li *ngFor="let item of works">
          <a [routerLink]="item.slug">{{item.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</section>
