import { Work } from './works.model';
import { Injectable } from "@angular/core";

@Injectable()
export class WorkService {
	private works: Work[] = [
		{
			slug: 'umran-seyhan',
			siteUrl: 'http://umranseyhan.com',
			title: 'Ümran Seyhan Official Website',
			summary: 'Official website of the Turkish psychotherapist Ümran Seyhan.',
			image: '/assets/images/works/umran-seyhan.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>AngularJS (v1.8)</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
			</ul>

			<h2>Roles:</h2>

			<ul>
				<li>PSD to HTML and CSS <small>(some pages and components as part of a team)</small></li>
				<li>Database connections</li> <li>Custom CMS integrations</li>
				<li>SEO ready configurations and improvements</li>
				<li>Custom e-mail service configurations</li>
				<li>Analytic tools integrations</li>
				<li>Testing processes</li>
			</ul>
			`,
		},
		{
			slug: 'id-iletisim',
			siteUrl: 'http://idiletisim.com.tr/',
			title: 'ID Iletisim Agency Website',
			summary: 'Established in 2002, ID, with its extensive portfolio of 50 actors – both established and emerging talent, is pro-active in building their careers by offering tailor-made management, PR and media relations and social media consultancy services, consistently striving for long-term career success and sustainability with maximum revenue and recognition, both locally and worldwide.',
			image: '/assets/images/works/id-iletisim.png',
			content: `
			<h2>Stack:</h2>

			<ul>
				<li>AngularJS (v1.8)</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS <small>(some pages and components as part of a team)</small></li> 
				<li>Database connections</li> 
				<li>Custom CMS integrations</li> 
				<li>Multi-language improvements</li> 
				<li>SEO ready configurations and improvements</li> 
				<li>Custom e-mail service configurations</li> 
				<li>Analytic tools integrations</li> 
				<li>Testing processes</li> 
			</ul>
			`
		},
		{
			slug: 'recode',
			siteUrl: 'http://recode.co',
			title: 'Studio Recode Website',
			summary: 'Studio Recode is an international brand technology studio focusing on end to end brand experience design and implementation. ',
			image: '/assets/images/works/studio-recode.png',
			content: `
			<h2>Stack:</h2>

			<ul>
				<li>AngularJS (v1.8)</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS</li> 
				<li>Database connections</li> 
				<li>Custom CMS integrations</li> 
				<li>SEO ready configurations and improvements</li> 
				<li>Custom e-mail service configurations</li> 
				<li>Analytic tools integrations</li> 
				<li>Testing processes</li> 
			</ul>
			`,
		},
		{
			slug: 'miq-agency',
			siteUrl: 'http://miq.com.tr/',
			title: 'MIQ Agency Website',
			summary: 'MIQ Creative. Marketing and Advertising agency from Istanbul',
			image: '/assets/images/works/miq-agency.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>AngularJS (v1.8)</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS</li> 
				<li>Database connections</li> 
				<li>Custom CMS integrations</li> 
				<li>SEO ready configurations and improvements</li> 
				<li>Custom e-mail service configurations</li> 
				<li>Analytic tools integrations</li> 
				<li>Testing processes</li> 
			</ul>
			`
		},
		{
			slug: 'crane-global',
			siteUrl: 'https://crane.aero/',
			title: 'Crane Global Website',
			summary: 'Crane is an Airline and Travel IT Solution brand of Hitit. We are focused on the changing trends of the aviation industry by offering modern, fast, cost efficient and smart solutions.',
			image: '/assets/images/works/crane-global.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>BlazeJS</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS <small>(lots of pages and components, as part of a small team)</small></li> 
				<li>Database connections <small>(some of pages as part of a team)</small></li> 
				<li>Custom CMS integration <small>(some of pages as part of a team)</small></li> 
				<li>SEO ready configurations and improvements</li> <li>Analytic tools integrations</li> 
				<li>Brand mailings</li> <li>Testing processes</li> 
			</ul>
			`,
		},
		{
			slug: 'bonesta',
			siteUrl: 'https://bonesta.com.tr/',
			title: 'Bonesta E-Commerce Website',
			summary: 'Bonesta has been in the textile industry for over 40 years and it is focused on swimwear for 15 years now. With its high quality, form fitting, glamorous designs, Bonesta Swimwear soon became one of the leading brands in the industry and women’s favorite.',
			image: '/assets/images/works/bonesta.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>AngularJS (v1.8)</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS <small>(some pages and components as part of a team)</small></li> 
				<li>SEO ready configurations and improvements</li> <li>Analytic tools integrations</li> 
				<li>Brand mailings and signatures</li> 
				<li>Testing processes</li> 
			</ul>
			`,
		},
		{
			slug: 'rakha',
			siteUrl: 'http://rakha.co.uk',
			title: 'Rakha E-Commerce Website',
			summary: 'While fashion is transient, our ethos is drawn from the classics: understated, contemporary garments that will take you throughout seasons for years. Unlike other brands Rakha does not follow seasons but rather focuses on re-designing those classic pieces that could be worn for generations. The brands approach to sustainability is through creating garments made from bio-degradable or repurposed materials.',
			image: '/assets/images/works/rakha.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>BlazeJS</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS <small>(lots of pages and components, as part of a small team)</small></li>
				<li>Database connections <small>(some of pages as part of a team)</small></li> 
				<li>SEO ready configurations and improvements</li> 
				<li>Analytic tools integrations</li> 
				<li>MailChimp integrations</li> 
				<li>Brand mailings and signatures</li> 
				<li>Testing processes</li> 
			</ul>
			`,
		},
		{
			slug: 'hitit-cs',
			siteUrl: 'http://hititcs.com',
			title: 'Hitit CS Global Website',
			summary: 'Hitit Computer Services was established in 1994 by executives who gained experience by working within the Turkish Airlines family and thus, were able to foresee that customizable software needs of the global aviation sector would constantly increase. Starting out with the vision of becoming a global technology company, Hitit is named after the ancient Hittite civilization of Anatolia.',
			image: '/assets/images/works/hitit.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>BlazeJS</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS <small>(lots of pages and components, as part of a small team)</small></li> 
				<li>Database connections <small>(some of pages as part of a team)</small></li> 
				<li>Custom CMS integration <small>(some of pages as part of a team)</small></li>
				<li>SEO ready configurations and improvements</li> <li>Analytic tools integrations</li> 
				<li>Brand mailings</li> <li>Testing processes</li>
			</ul>
			`,
		},
		{
			slug: 'cansu-dere',
			siteUrl: 'http://cansudere.com.tr',
			title: 'Cansu Dere Official Website',
			summary: 'Cansu Dere is a Turkish film and television actress, model, and beauty pageant runner-up',
			image: '/assets/images/works/cansu-dere.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>BlazeJS</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS</li> 
				<li>Database connections</li> 
				<li>Custom CMS integrations</li> 
				<li>Multi-language improvements</li> 
				<li>SEO ready configurations and improvements</li> 
				<li>Custom e-mail service configurations</li> 
				<li>Analytic tools integrations</li> 
				<li>Testing processes</li> 
			</ul>
			`,
		},
		{
			slug: 'basi-pilates',
			siteUrl: 'http://basipilates.com.tr',
			title: 'BASI Pilates',
			summary: 'BASI Pilates, founded by Rael Isacowitz in 1989 in the USA, is represented by more than 100 host studios and 10.000 graduates in over 30 countries. BASI stands for Body Arts and Science International®, which is the name of the academy’s holding company and an affirmation of our approach to Pilates teacher training.',
			image: '/assets/images/works/basi-pilates.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>AngularJS (v1.8)</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
				<li>Amazon API</li> 
				<li>Mailchimp API</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS</li> 
				<li>Database connections</li> 
				<li>Custom CMS integrations</li> 
				<li>SEO ready configurations and improvements</li> 
				<li>Custom e-mail service configurations</li> 
				<li>Analytic tools integrations</li> 
				<li>Testing processes</li> 
			</ul>
			`
		},
		{
			slug: 'basi-systems',
			siteUrl: 'http://basisystems.com',
			title: 'BASI Systems',
			summary: 'BASI Systems introduces innovations that fully facilitate the precision and flow of movement inherent in the BASI approach.',
			image: '/assets/images/works/basi-systems.png',
			content: `
			<h2>Stack: </h2>

			<ul> 
				<li>AngularJS (v1.8)</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
				<li>Amazon API</li> 
				<li>Mailchimp API</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS <small>(lots of pages and components, as part of a small team)</small></li> 
				<li>Database connections <small>(some of pages as part of a team)</small></li> 
				<li>Custom CMS integration <small>(some of pages as part of a team)</small></li>
				<li>SEO ready configurations and improvements</li> <li>Analytic tools integrations</li> 
				<li>Brand mailings</li> <li>Testing processes</li>
			</ul>
			`,
		},
		{
			slug: 'nbs',
			siteUrl: 'https://www.nbshr.net/',
			title: 'NBS Human Resources',
			summary: 'NBS is providing comprehensive services in the field of recruitment. It applies selection- placement policies in compliance with level and expectations of each position.',
			image: '/assets/images/works/nbshr.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>AngularJS (v1.8)</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
				<li>Amazon API</li> 
				<li>Mailchimp API</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS <small>(lots of pages and components, as part of a small team)</small></li> 
				<li>Database connections</li> 
				<li>Custom CMS integration</li>
				<li>SEO ready configurations and improvements</li> 
				<li>Analytic tools integrations</li> 
				<li>Brand mailings</li> <li>Testing processes</li>
				<li>Custom mail signatures</li>
			</ul>
			`
		},
		{
			slug: 'brandkit',
			siteUrl: 'http://brandkit.basisystems.com',
			title: 'BASI Brandkit (DAM)',
			summary: 'Brandkit is a Digital Asset Management tool and it developed for BASI Systems. It has complex file, user and permission systems. The product has been re-coded after the design and coding of the prototype.',
			image: '/assets/images/works/brandkit.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>AngularJS (v1.8)</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
				<li>Amazon API</li> 
				<li>Mailchimp API</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>Prototype design ve develop</li> 
				<li>SketchApp to HTML and CSS after re-design</li> 
				<li>Improvements for user, file and permission systems</li> 
				<li>Email notification service</li> 
				<li>Amazon and Mailchimp API operations</li> 
				<li>Database connections</li> 
				<li>Presentation about project flow ve data architecture</li> 
			</ul>
			`,
		},
		{
			slug: 'vvg',
			siteUrl: 'https://vanveengrab.com/',
			title: 'Van Veen Grab E-Commerce Website',
			summary: 'Inspirational Van Veen Grab collection is specially designed with very unique fabric patterns and high quality Italian and Spanish fabric manufacturers who have guided the world fashion.',
			image: '/assets/images/works/vvg.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>AngularJS (v1.8)</li>
				<li>SCSS</li> 
				<li>MeteorJS</li>
				<li>MongoDB</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>Fully PSD to HTML and CSS</li>
				<li>SEO ready configurations and improvements</li>
				<li>Analytic tools integrations</li> 
			</ul>
			`,
		},
		{
			slug: 'look',
			siteUrl: 'http://look.agency',
			title: 'Agency Look Website',
			summary: 'Look is an experience design agency.',
			image: '/assets/images/works/look.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>Angular (v6)</li> 
				<li>SCSS</li> 
				<li>IIS Node</li> 
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS <small>(lots of pages and components, as part of a small team)</small></li> 
				<li>SEO ready configurations and improvements</li> 
				<li>Analytic tools integrations</li>
				<li>Project maintenance</li>
			</ul>
			`,
		},
		{
			slug: 'aksigorta',
			siteUrl: 'https://aksigorta.com.tr',
			title: 'Aksigorta',
			summary: 'Aksigorta is an insurance service',
			image: '/assets/images/works/aksigorta.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>Angular (v5)</li> 
				<li>SCSS</li> 
				<li>IIS Node</li> 
				<li>Angular Google Maps (agm)</li>
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>PSD to HTML and CSS <small>(lots of pages and components, as part of a small team)</small></li> 
				<li>Custom map</li>
				<li>SEO ready configurations and improvements</li> 
				<li>Analytic tools integrations</li> 
				<li>Project maintenance</li>
			</ul>
			`,
		},
		{
			slug: 'fastpay',
			siteUrl: 'https://fastpay.com.tr',
			title: 'fastPay',
			summary: "DenizBank's fastPay brings a breath of fresh air to the banking transactions, and presents itself to you for a better experience with its new design.",
			image: '/assets/images/works/fastpay.jpg',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>ES6</li> 
				<li>SCSS</li> 
				<li>JQuery</li> 
				<li>GulpJS</li> 
				<li>VueJs</li>
				<li>AMP</li>
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>Fully front-end</li>
				<li>PSD to HTML and CSS</li> 
				<li>Custom map</li>
				<li>Analytic tools integrations</li> 
				<li>Project maintenance</li>
			</ul>
			`,
		},
		{
			slug: 'colour-ninja',
			siteUrl: 'https://colour.ninja',
			title: 'Colour.Ninja',
			summary: "Colour Ninja is a plain color transition tool. It calculates the transition colors between two picked colors. Developed with ReactJS and Styled Components as a tool to help designers.",
			image: '/assets/images/works/colour-ninja.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>ReactJS</li> 
				<li>Styled Components</li> 
				<li>Webpack</li> 
			</ul>
			`,
		},
		{
			slug: 'rigorent',
			siteUrl: 'https://rigorent.com',
			title: 'Rigorent',
			summary: "You can rent a car quickly and easily with Rigorent and get 24/7 support. You can pick up your car from hundreds of points or ask the valet to bring the car to your location. ",
			image: '/assets/images/works/rigorent.png',
			content: `
			<h2>Stack:</h2>

			<ul> 
				<li>Angular (v8)</li> 
				<li>Angular Universal</li> 
				<li>Angular Google Maps (agm)</li>
				<li>Angular Translate</li>
				<li>SCSS</li>
			</ul>

			<h2>Roles:</h2>

			<ul> 
				<li>Invision to HTML and CSS</li> 
				<li>Flex booking experience</li>
				<li>Service integrations</li>
				<li>Membership system</li>
				<li>UX based designed custom date and location picker</li>
				<li>Inteactive map</li>
				<li>Analytic tools integrations</li> 
			</ul>
			`,
		}
	];
	
	getWorks(limit?: number): Work[] {
		if (limit) {
			return this.works.slice(-limit).reverse();
		} else {
			return this.works.slice().reverse();
		}
	}
	
	getWork(slug: string): Work | undefined {
		return this.works.find(
			(work: Work) => {
				return work.slug === slug;
			}
			);
		}
	}
	