<section class="about">
  <div class="container"> 
    <div class="inner">
      <article>
        <h1>About Me</h1>
        <p><strong>Keywords</strong>: <a href="https://www.w3.org/html/" target="_blank">HTML5</a>, <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS3" target="_blank">CSS3</a>, <a href="http://sass-lang.com/" target="_blank">SASS</a>, <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank">JavaScript</a>, <a href="https://angular.io" target="_blank">Angular</a>, <a href="https://jquery.com/" target="_blank">JQuery</a>, <a href="https://vuejs.org/" target="_blank">VueJS</a>, <a href="https://reactjs.org/" target="_blank">ReactJS</a>, <a href="https://www.meteor.com/" target="_blank">MeteorJS</a>, <a href="https://www.mongodb.com/" target="_blank">MongoDB</a>, <a href="https://gulpjs.com/" target="_blank">GulpJS</a>, <a href="https://github.com" target="_blank">GitHub</a>, <a href="https://moz.com/" target="_blank">SEO-Ready</a>, Atomic Design, BEM, </p> 
        <p><strong>Workarea</strong>: <a href="https://www.apple.com" target="_blank">MacOS</a>, <a href="https://code.visualstudio.com/" target="_blank">VSCode</a>, <a href="https://www.jetbrains.com/webstorm/" target="_blank">WebStorm</a>, <a href="https://nodejs.org/" target="_blank">NodeJS</a>, <a href="https://www.sketchapp.com/" target="_blank">Sketch</a>, <a href="https://www.adobe.com/products/xd.html" target="_blank">Adobe XD</a>, Invision, Zeplin, <a href="http://www.adobe.com/tr/products/photoshop.html" target="_blank">Adobe Photoshop</a>, <a href="https://slack.com/" target="_blank">Slack</a>, <a href="https://trello.com/" target="_blank">Trello</a>, <a href="https://notion.so" target="_blank">Notion</a></p> 
        <p><strong>Touched</strong>: UX Design, Prototyping, <a href="https://nginx.org/" target="_blank">Nginx</a>, <a href="https://www.ubuntu.com/" target="_blank">Ubuntu</a>, Bash Script, <a href="https://aws.amazon.com" target="_blank">Amazon</a>, <a href="https://www.digitalocean.com/" target="_blank">DigitalOcean</a>, <a href="http://pm2.keymetrics.io/" target="_blank">PM2</a>, Content Management Systems, <a href="https://www.wrike.com/" target="_blank">Wrike</a>, Digital Asset Management, <a href="https://wordpress.org/" target="_blank">Wordpress</a>, <a href="https://www.shopify.com/" target="_blank">Shopify, Jira, ClickUp</a></p>
        <p>You can see some of the projects that I have developed and contributed from the <a class="custom" routerLink="/works">Works</a> page.</p>
        <p>You can also check out <a href="https://portfolio.ege.im/files/Egemen-Unuvar-CV.pdf" class="custom" target="_blank">my CV</a>.</p>
        <figure>
          <img src="https://media.giphy.com/media/3o6ZtevvOpPSUs0swg/giphy.gif" alt="">
        </figure>
      </article>
    </div>
  </div>
</section>