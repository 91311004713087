<section class="work-detail" ng-if="work">
  <div class="container">
    <div class="inner">
      <article>
        <h1>{{work.title}}</h1>
        <p><strong>URL: </strong><a [href]="work.siteUrl">{{work.siteUrl}}</a></p>
        <p><strong>About: </strong>{{work.summary}}</p>
        <div class="content" [innerHtml]="work.content"></div>
        <figure>
          <div class="browser">
            <!-- <img  src="/assets/images/content/browser-head.png" alt=""> -->
            <img [src]="work.image" alt="">
          </div>
        </figure>
      </article>
    </div>
  </div>
</section>